@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.editr{
    .editr--content{
        padding: 7px 10px;
        min-height: 120px;
    }
    &.single-line{
        .editr--content{
            min-height: 33px;
        }
    }
    .editr--toolbar{
        display: none;
    }
}
.editr.toolbar{
    .editr--toolbar{
        display: flex;
    }
}

#invoice-form{
    .discount-input,
    .iva-input{
        max-width: 70px;
        /*float: right;*/
        display: inline-block;
        margin-right: 6px;
    }    
}