$cWhite: #FFF;
$cBackgroundDark: #F0F0F0;
$cBackground: #F5F5F5;
$cBackgroundLight: #F9F9F9;

$cShadowDark: #ccc;
$cShadow: #ddd;

$cTextLight: #999;
$cText: #666;
$cTextDark: #333;

$dfMargin: 16px;

$cOk: #26B99A;
$cKo: #d9534f;

$cOkBg: #f5fff5;
$cKoBg: #fff5f5;

$cStatePending: #fdba25;
$cStatePendingShipment: #fd8925;
$cStateOnDeposit: #fd7f25;
$cStateStock: #bda037;
$cStateShipped: #205CCA;
$cStateInPort: #79D5F8;
$cStateDelivered: #40b99a;
$cStateCancelled: #d8534f;
$cStateTrasfered: #5b1d82;
$cStateRejected: #fe643e;
$cStateMerma: #333;

$cDanger: #721c24;
$cDangerBg: #f5c6cb;
$cWarning: #856404;
$cWarningBg: #ffeeba;
$cInfo: #004085;
$cInfoBg: #cce5ff;
$cSuccess: #155724;
$cSuccessBg: #d4edda;

$cContractTypeDeposit: #337ab7;

$cContractStateActive: $cStatePending;
$cContractStateShipped: $cStateShipped;
$cContractStateClosed: $cStateDelivered;
$cContractStateCancelled: $cStateCancelled;

%shadow{
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.12), 0 1px 6px 0 rgba(0,0,0,0.12);
}
%rounded{
    border-radius: 2px;
}