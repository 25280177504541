.shipment-contract-data-info{
    margin-bottom: 10px;
}
.x_panel.shipment-container{
    .shipment-container-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 8px;
        button.btn{
            margin-bottom: 0;
        }
    }
    .shipment-container-subheader{
        padding-left: 8px
    }
    padding: 0;
    .x_title{
        padding: 8px 16px;
    }
    .custom_x_body{
        padding: 8px 16px;
    }
    &.has-assigned-lines{
        .x_title{

            background-color: $cWarningBg;
        }
    }
}
.modal-mask{
.modal-dialog{
.modal-content{
    
    .mx-calendar-icon{
        height: auto;
    }
    .subsection{
        margin-bottom: 10px;
    }
    .subsection-title{
        margin-bottom: 0;
        display: inline-block;
    }
    hr{
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .select-unselect-all{
        margin-top: 10px;
        button{
            margin-bottom: 0;
        }
    }
    .modal-body{
        max-height: 70vh;
            
    }
    .modal-footer{
        .btn{
            margin-bottom: 0;;
        }
    }
}
}
}

.child-shipments-dropdown{
    position: absolute;
    background: #fff;
    margin-top: 0;
    border: 1px solid #D9DEE4;
    -webkit-box-shadow: none;
    right: auto;
    left: 0;
    width: 220px;
    & > li > a{
        padding: 8px  16px;
    }
}