#reports-select{
    margin-bottom: $dfMargin*2;
}

.report-title{
    margin-bottom: $dfMargin;
    small{
        margin-top: 14px;
        display: inline-block;
        i{
            color: $cShadowDark;
        }
    }
}
.report-totals-row{
/*    display: flex;
    flex-direction: row;*/

    .report-total-box{
        /*flex: 1;*/
        .tile-stats{
            padding-right: $dfMargin;
            text-align: right;
            .count.small-text{
                font-size: 30px;
            }
        }
        h3{
            white-space: nowrap;
        }
    }
}

.report-results-wrap{
    min-height: 300px;
}

.customers-hr{
    margin: 4px 0;
}


.report-info-box-wrap{
    min-width: 450px;
    padding: $dfMargin;
}


.report-header{
    .report-totals-row{
        display: flex;
        .report-total-box{
            flex: 1;
        }
    }
}