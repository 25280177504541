/*@import "~bootstrap/scss/bootstrap";*/
/*@import '~bootstrap/dist/css/bootstrap.css';*/
/*@import '~toastr/build/css/toastr.min.css';*/
@import '~toastr/toastr.scss';
@import 'vars.scss';
@import 'homepage.scss';
@import 'contracts.scss';
@import 'shipments.scss';
@import 'invoices.scss';
@import 'reports.scss';
@import '../../node_modules/vuejs-dialog/dist/vuejs-dialog.min.css';
@import '../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';


@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
         transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}



#contracts-table,
#shipments-table{
    tr.highlighted{
        background-color: $cKoBg;
    }
}

.btn.btn-link .fa.cOk,
fa.cOk,
.cOk{
    color: $cOk;
}
.x_title .cKo,
.btn.btn-link .fa.cKo,
fa.cKo,
.cKo{
    color: $cKo;
}


.shipment-state-label-wrap{
    cursor: pointer;
    &.inline{
        .shipment-state-label{
            display: inline-block;
        }   
    }   
       
    .shipment-state-label{
        display: block;
        padding: 4px 6px;
        &.pending{
            background-color: $cStatePending;
        }
        &.pending_shipment{
            background-color: $cStatePendingShipment;
        }
        &.in_port{
            background-color: $cStateInPort;
        }
        &.on_deposit{
            background-color: $cStateOnDeposit;
        }
        &.stock{
            background-color: $cStateStock;
        }
        &.shipped{
            background-color: $cStateShipped;
        }
        &.delivered{
            background-color: $cStateDelivered;
        }
        &.cancelled{
            background-color: $cStateCancelled;
        }
        &.rejected{
            background-color: $cStateRejected;
        }
        &.transfered{
            background-color: $cStateTrasfered;
        }
        &.merma{
            background-color: $cStateMerma;
        }
    }
    
    .dropdown-menu{
        li{
            a{
                padding: 6px 12px;
                border-bottom: 1px solid $cBackground;
            }
            &:last-child a{
                border-bottom: none;
            }
        }
    }
}

/*
    .report-total-box .tile-stats{
        &.pending .count{
            color: $cStatePending;
        }
        &.pending_shipment .count{
            color: $cStatePendingShipment;
        }
        &.in_port .count{
            color: $cStateInPort;
        }
        &.on_deposit .count{
            color: $cStateOnDeposit;
        }
        &.stock .count{
            color: $cStateStock;
        }
        &.shipped .count{
            color: $cStateShipped;
        }
        &.delivered .count{
            color: $cStateDelivered;
        }
        &.cancelled .count{
            color: $cStateCancelled;
        }
        &.rejected .count{
            color: $cStateRejected;
        }
        &.transfered .count{
            color: $cStateTrasfered;
        }
        &.merma .count{
            color: $cStateMerma;
        }
    }
*/
    
.shipment-row{
    padding: 4px 6px;    
}
    /*.report-total-box .tile-stats,*/
    .shipment-row{
        $alpha : .1;
        
        &.pending{
            background-color: rgba( $cStatePending, $alpha );
        }
        &.pending_shipment{
            background-color: rgba( $cStatePendingShipment, $alpha );
        }
        &.in_port{
            background-color: rgba( $cStateInPort, $alpha);
        }
        &.on_deposit{
            background-color: rgba( $cStateOnDeposit, $alpha);
        }
        &.stock{
            background-color: rgba( $cStateStock, $alpha);
        }
        &.shipped{
            background-color: rgba( $cStateShipped, $alpha);
        }
        &.delivered{
            background-color: rgba( $cStateDelivered, $alpha);
        }
        &.cancelled{
            background-color: rgba( $cStateCancelled, $alpha);
        }
        &.rejected{
            background-color: rgba( $cStateRejected, $alpha);
        }
        &.transfered{
            background-color: rgba( $cStateTrasfered, $alpha);
        }
        &.merma{
            background-color: rgba( $cStateMerma, $alpha);
        }
    }

    
    .contract-row{
        $alpha : .1;
        &.contract-row-active{
            background-color: rgba( $cContractStateActive, $alpha );
        }
        &.contract-row-shipped{
            background-color: rgba( $cContractStateShipped, $alpha);
        }
        &.contract-row-closed{
            background-color: rgba( $cContractStateClosed, $alpha);
        }
        &.contract-row-cancelled{
            background-color: rgba( $cContractStateCancelled, $alpha);
        }
    }

.entity-detail-info{
    ul{
        padding-left: 0;
    }
    li{
        list-style: none;
    }
}
.x_title{
    display: flex;
    justify-content: space-between;
    h2{
        display: inline-block;
        width: auto;
        small{
            display: inline;    
        }
    }
    .btn{
        margin: 0;
        margin-left: 6px;
    }
}



.error-page-wrap{
    margin-top: 20%;
}
.dropdown-menu.dropdown-usermenu{
    li{
        a{
            padding-top: 14px !important;
            padding-bottom: 14px !important;
        }
    }
}

.media{
    .date{
        &.danger{
            .day, .month{
                color: $cDanger;
            }
            background-color: $cDangerBg;
        }
        &.warning{
            .day, .month{
                color: $cWarning;
            }
            background-color: $cWarningBg;
            
        }
        &.info{
            .day, .month{
                color: $cInfo;
            }
            background-color: $cInfoBg;
            
        }
    }
}

.alerts-wrap{
    a{
        color: $cWhite;
    }
    .alert-body{
        display: inline-block;
        width: 100%;
        padding-left: 40px;
    }
    .alert-body-with-btn{
        /*padding-right: 140px;*/
        .btn{
            background-color: $cBackground;
            color: $cTextDark;
            margin:0;
            margin-left: 8px;
            @media(max-width:576px){
                margin-top: 8px;
                margin-bottom: 8px;
            }
            &:hover{
            background-color: $cBackgroundDark;
            /*color: $cTextLight;*/
                
            }
        }
    }
/*    .btn-secondary{
        color: $cTextDark;
        position: absolute;
        right: 24px;
        top: 16px;
        margin: 0;

    }*/
    .fa-warning{
        position: absolute;
        font-size: 22px;
        left: 24px;
    }
}

#new-dua-modal{
    .mx-input-append{
        svg{
            display: none;
        }
    }
}
// .results-table-loader {
    .loader {
        animation: 1.5s linear infinite spinner;
        color: $cOk;
    }
// }

.x_panel .toggle{
    float: none;
    width: auto;
}

.no-wrap{
    white-space: nowrap;
}

.calculated-value-icon{
    color: $cShadowDark;
}

.remove-bloquer-wrap{
    form{
        
    }
    h5{
        float: left;
        margin-top: 4px;
        margin-bottom: 0;
        cursor: pointer;
    }
    input[type="checkbox"]{
        cursor: pointer;
        margin-top: 5px;
        margin-left: 10px;
    }
    label{
        margin-right: 50px;
        margin-bottom: 0;
        vertical-align: middle;
    }
    button{
        margin-bottom: 0;
        i.fa.fa-trash{
            color: $cWhite !important;
        }
    }
}

.typeahead-wrap{
    position: relative;
    .item{
        font-size: inherit !important;
    }
    .typeahead-clear-btn{
        position: absolute;
        right: 28px;
        top: 4px;
        font-size: 18px;
        cursor: pointer;
    }
    .ui.search{
         border: 1px solid #ccc !important;
    }
    
    .ui.dropdown:not(.button) > .default.text, 
    .ui.default.dropdown:not(.button) > .text{
            color: #999!important;
            font-size: 14px;
    }    
    
}

.stock-guarantee-label{
        @media(max-width:576px){
            margin: 20px;
        }

}

.typeaheadmulti-wrap{
    .item{
        font-size: inherit !important;
    }
    .label{
    .icon{
        display: inline-block;
        width: 10px!important;
        height: 10px!important;
        background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDM0OC4zMzMgMzQ4LjMzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQ4LjMzMyAzNDguMzM0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTMzNi41NTksNjguNjExTDIzMS4wMTYsMTc0LjE2NWwxMDUuNTQzLDEwNS41NDljMTUuNjk5LDE1LjcwNSwxNS42OTksNDEuMTQ1LDAsNTYuODUgICBjLTcuODQ0LDcuODQ0LTE4LjEyOCwxMS43NjktMjguNDA3LDExLjc2OWMtMTAuMjk2LDAtMjAuNTgxLTMuOTE5LTI4LjQxOS0xMS43NjlMMTc0LjE2NywyMzEuMDAzTDY4LjYwOSwzMzYuNTYzICAgYy03Ljg0Myw3Ljg0NC0xOC4xMjgsMTEuNzY5LTI4LjQxNiwxMS43NjljLTEwLjI4NSwwLTIwLjU2My0zLjkxOS0yOC40MTMtMTEuNzY5Yy0xNS42OTktMTUuNjk4LTE1LjY5OS00MS4xMzksMC01Ni44NSAgIGwxMDUuNTQtMTA1LjU0OUwxMS43NzQsNjguNjExYy0xNS42OTktMTUuNjk5LTE1LjY5OS00MS4xNDUsMC01Ni44NDRjMTUuNjk2LTE1LjY4Nyw0MS4xMjctMTUuNjg3LDU2LjgyOSwwbDEwNS41NjMsMTA1LjU1NCAgIEwyNzkuNzIxLDExLjc2N2MxNS43MDUtMTUuNjg3LDQxLjEzOS0xNS42ODcsNTYuODMyLDBDMzUyLjI1OCwyNy40NjYsMzUyLjI1OCw1Mi45MTIsMzM2LjU1OSw2OC42MTF6IiBmaWxsPSIjMDAwMDAwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
        background-size: cover;
    }
    }
}

.has-error input,
.has-error .editr,
.has-error .typeahead-wrap .ui.search{
    border: 1px solid #a94442 !important;
    background-color: #fffAfA !important;
}

.btn.btn-link {
    &:hover{
        background-color: $cShadow;
    }
    .fa{
        color: $cText;
        margin-right: 4px;
        margin-left: 4px;
    }
}

label.required{
    position: relative;
    &:after{
        content: '*';
        color: $cOk;
        position: absolute;
        right: -12px;
        top: -1px;
        font-size: 18px;
    }
}
.has-error label.required{
    &:after{
        color: $cKo;
    }
}

body{
    color: $cText;
}
.page-content-wrap.no-title{
    .page-title{
        display: none;
    }
    .page-title-vue{
        display: block;
    }
}
.text-secondary{
    color: $cTextLight;
    
}
.page-actions{
    margin-bottom: $dfMargin;
    display: inline-block;
    width: 100%;
}

.right_col{
    min-height: 95vh;
}
.no-margin{
    margin: 0;
}

.pointer,
.fa.pointer{
    cursor: pointer;
}

body.dg-open{
    overflow: auto;
}

select{
    .select-placeholder{
        color: #999;
    }
    &.empty{
        color: #999;    
        option{
            color: #000;    
            &.select-placeholder{
                color: #999;
            }
        }
    }
}



fieldset{
    margin-bottom: $dfMargin;
    padding: 0 $dfMargin $dfMargin/2 $dfMargin;
    border: 1px solid $cShadow;
    background-color: $cBackgroundLight;
    legend{
        display: inline;
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -10px;
        margin-bottom: 0;
        border: 0px;
        border-top: 1px solid $cShadow;
        font-size: 14px;
        color: $cText;
        text-transform: uppercase;
        background-color: $cBackgroundLight;
    }
}

.results-counters{
    font-size: 12px;
    background-color: $cBackgroundLight;
    padding: 5px;
    text-align: right;
    margin-bottom: $dfMargin;
}

.results-table-filters{
    width: 100%;
    .row{
        margin-bottom: 8px;
    }
    .col-md-3{
        @media (max-width: 991px){
            margin-bottom: $dfMargin/2;
        }
    }
    .clear-filter-btn{
        position: absolute;
        top: -24px;
        right: 8px;
        background-color: $cBackgroundLight;
        @media (max-width: 991px){
            margin-top: 0;
        }
        &:focus,
        &:hover{
            text-decoration: none;
        }
    }
}

.table-filtrable{
    .order-desc,
    .order-asc{
        cursor: pointer;
    }
    th{
        &.active{
            .sortable-table-icon{
                display: inline;
            }
        }
        .sortable-table-icon{
            display: none;
            margin-left: 5px;
            &.fa-sort-down{
                /*margin-bottom: 4px;*/
            }
        }
    }
}

table{
    .btn{
        margin: 0 4px;
    }
    ul{
        margin-bottom: 0;
    }
}
.fieldset-actions{
    padding-top: 8px;
}
fieldset{
    position: relative;
    .fieldset-legend{
        cursor: pointer;
    }
    .fieldset-loader{
        background: rgba(255,255,255,.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
        margin: $dfMargin $dfMargin/2 $dfMargin/2 $dfMargin/2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }
}

.nav.navbar-nav.navbar-left{
    float: left;
    margin: 0;
}
.nav.navbar-nav.navbar-right{
    width: auto;
}
#global-search{
    margin-top: 12px;
    position: relative;
    .loader{
        position: absolute;
        top: 16px;
        right: 5px;;
        z-index: 10;
        font-size: 18px;;
        color: $cOk;
    }
    .global-search-dropdown{
        & > ul{
            padding: $dfMargin/2 $dfMargin;
            width: 600px;
            max-width: 75vw;
            left: 0;
            right: auto;
        }
        .global-search-result-group-name{
            color: $cTextLight;
        }
        .global-search-result-group{
            list-style: none;
            padding-left: 0;
            .global-search-result{
                border-bottom: 1px solid $cShadow;
                
                &:last-child {
                    border-bottom: 0;
                }
                &:hover{
                    color: $cTextLight;
                    background: $cBackground;
                }
                a{
                    padding: 8px 16px;
                    cursor: pointer;
                    display: block;
                }
            }
        }
    }
}
.nav-sm #global-search{
    @media(max-width:576px){
        width: 160px;
    }
}
.mx-datepicker{
    width: auto !important;
    display: block !important;
}

#toast-container{
    .toast{
        display: flex;
        flex-direction: row;
        padding: 12px 22px 12px 50px;
    }
    .toast-close-button{
        position: absolute;
        top: 5px;
        right: 5px;
    }
}
#toast-container > div{
    padding-bottom: 0px;
}

.table-condensed .btn.btn-link{
    padding-top: 0px;
    padding-bottom: 0px;
}

.fa.fa-trash{
    color: $cKo !important;
}

#rt-client{
    position: relative;
    .rt-client-warning{
        position: absolute;
/*        bottom: -20px;
        right: 0;*/
        right: 40px;
        top: 50%;
        min-width: 400px;
        padding: 4px 16px;
        margin: 0;
        margin-top: -20px;
        z-index: 10;
    }
    .rt-client-connected{
        color: $cOk;
    }
    .rt-client-disconnected{
        color: $cKo;
    }    
}
#shipment_doc{
    display: none;
}


#shipment-doc-drop-box{
    cursor: pointer;
    &.is-dragover{
        background: $cOk;
        color: $cWhite;
    }
    .empty-wrap{
        
    }
    .ready-wrap{
        display: none;
        color: $cOk;
    }
    &.panel.ready{
        .empty-wrap{
            display: none;
        }
        .ready-wrap{
            display: block;
        }
    }
/*    #shipment-doc-drop-box-empty-label{
        
    }
        #shipment-doc-drop-box-filename-label{
            display: none;
        }*/
}
td[align="right"]{
    padding-right: 10px!important;
}
.tr-bold{
    td{
        font-weight: bold;        
    }
}
.td-bold{
    font-weight: bold;            
}
th,
td{
    &.td-xs{
        width: 80px;
    }
    &.td-xs-1{
        width: 100px;
    }
    &.td-sm{
        width: 120px;
    }
    &.td-sm-1{
        width: 130px;
    }
    &.td-md{
        width: 150px;
    }
    &.td-md-1{
        width: 160px;
    }
    &.td-md-2{
        width: 170px;
    }
}


label.upper-label{
    color: $cTextLight;
    font-size: 12px;
    display: block;
    font-weight: bold;
    margin-bottom: 1px;
}
span.upper-value{
    color: $cTextDark;
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
}

.shipment-comment-content{
    white-space: pre;
    padding: $dfMargin/2 $dfMargin;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
    .modal-header{
        button.close{
            padding: 6px 12px;
            margin: 0;
            position: absolute;
            right: 8px;
            top: 10px;
            font-size: 28px;            
        }
    }
    .modal-dialog{
        .modal-body{        
            max-height: 90vh;
            overflow: visible;
            &.scrollable{
                overflow-y: auto;                            
            }
        }
    }
}

.shipment-doc-state-icon{
    margin-right: 8px;
    font-size: 18px;
    &.ok{
        color: $cOk;
    }
    &.ko{
        color: $cKo;
    }
}



/* THEME OVERRIDES */
td span{
    line-height: inherit;
}

.modal-title{
    text-align: left;
}
.x_title{
    padding: 1px 0px 6px;
    i {
        color: $cOk;
    }
    .btn{
        i {
            color: $cWhite;
        }        
    }
    &.collapsed{
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
.x_panel{
    padding: $dfMargin/2 $dfMargin;
}

.page-title-wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    // margin-bottom: 10px;
    min-height: 40px;

    .page-title{
        width: auto;
        margin: 0;
        padding: 0;
        height: auto;
        h3{
            
            margin: 0;
        }
    // .title_right{        
    //     .pull-right{
    //         margin: 10px 0;
    //             margin-left: 10px;
    //         &:last-child{
    //             margin-left: 0px;
    //         }
    //     }
    // }
    }
    .btn{
        margin: 0;
        margin-left: 6px;
    }
}
.form-control-admin-show{
    border: 0;
    background: $cBackgroundLight;
    box-shadow: none;
    color: $cText;
    .label{
        color: $cText;        
    }
}

input[type="checkbox"].form-control{
    height: auto;
}

.menu_section h3{
    margin: 20px 0 10px 0;
    color: $cShadowDark;
}
.login_content form input#username,
.login_content form input#password{
    box-shadow: none;
}

.dg-btn--cancel{
    color: #d9534f;
    background-color: #fff;
    border-color: #d43f3a;
}
.dg-btn--ok{
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}
/* THEME OVERRIDES END */

/* BS OVERRIDES */

.label-success{
    background-color: $cOk;
}
.table>thead>tr>th.active{
    background-color: transparent;
}

.side-menu {
    margin-top: 0!important;
    h3 {
        background-color: #333;
        margin-top: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        background: -webkit-gradient(linear, left top, left bottom, from(#334556), to(#2C4257)), #2A3F54;
        background: linear-gradient(#334556, #2C4257), #2A3F54;
        -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
        box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
    }
    li:first-child h3{
        margin-top: 0;
    }
    li > a{
        padding: 8px 15px;
    }
}

.nav-sm .nav.side-menu li a{
    padding: 7px 5px;
    i{
        font-size: 18px!important;
    }
}
/* BS OVERRIDES END */

.results-table-loader{
        background: rgba(255,255,255,.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
        margin: $dfMargin $dfMargin/2 $dfMargin/2 $dfMargin/2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        z-index: 10;
}

.typeaheadmulti-wrap{
    
    .multiselect{
        
        min-height: 34px;
    }
    .multiselect__select{
        height: 34px;
        padding: 0 8px;
    }
    .multiselect__tags{        
        min-height: 34px;
        padding: 0px 40px 0 8px;
        border: 1px solid $cShadowDark;
        color: #555;
    }
    .multiselect__input{
        min-height: 34px;
        line-height: 1;
        margin-bottom: 0;
        font-size: 12px;
        
    }
    .multiselect__tag{
        background-color: $cBackground;
        border: 1px solid  $cShadow;
        color: $cText;   
        margin-top: 2px;
        margin-bottom: 2px;
        .multiselect__tag-icon:after{
            color: $cText;            
        }
        .multiselect__tag-icon:focus, 
        .multiselect__tag-icon:hover{
            background-color: $cBackgroundDark;
            &:after{
                color: $cTextDark;
                
            }
        }
    }
    
    .multiselect__content-wrapper{
        min-width: 300px;
        border-color: $cShadowDark;
        .multiselect__content{
            padding-top: 60px;
            padding-bottom: 60px;
            position: relative;
        }


        .multiselect__option{
            padding: 4px 8px;
            min-height: 30px;
            line-height: 1;
            .multiselect-option-checkbox{
                margin-right: 8px;
            }
        }
        .multiselect__option--highlight{
            /*background-color: $cShadow;*/
            background-color: $cOkBg;
            color: $cText;
        }
        .multiselect__option--selected{
            background-color: $cBackgroundLight;        
            color: $cText;
        }
        .multiselect__option--selected.multiselect__option--highlight{
            /*background-color: $cShadowDark;*/        
            background-color: $cKoBg;        
            color: $cText;
        }

        .multiselect-actions-bar{
            position: absolute;
            left: 0;
            right: 0;
            &.multiselect-actions-bar-top{
                top: 0px;            
                border-bottom: 1px solid $cShadowDark;
            }
            &.multiselect-actions-bar-bottom{
                bottom: 0px;            
                border-top: 1px solid $cShadowDark;
            }
            & button{
                margin: 8px;
            }

        }
    }
}

.menu-homepage-logo{
        display: blockM
    }
        
@media(max-width:576px){
    .user-profile.dropdown-toggle{
        .user-profile-dropdown-email{
                display: none;
        }
        span.fa{
            font-size: 24px;
            font-weight: bold;      
            vertical-align: middle;
            color: $cTextLight;
        }

    }
    #rt-client{
        display: none;
    }
    .menu-homepage-logo{
        display: blockM
    }
}

.site_title i.fa{
    border: 0;
    display: none;
}
.nav-sm .site_title i.fa{
    display: block;
}

.c-ok{
    color: $cOk!important;
}
.c-warn{
    color: $cWarning!important;
}
.c-ko{
    color: $cKo!important;
}

.table tr{
    .actions-col{
        padding-top: 1px!important;
        padding-bottom: 1px!important;
        text-align: right;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-end;
        // align-items: center;
        .btn{
            margin: 0;
            margin-left: 4px;;
        }
    }
    &:first-child {
        .actions-col {
            // border-top: 0;
        }
    }
}

.alerts-menu-badge{
    background-color: $cKo;
    position: absolute ;
    right: 10px;;
    font-size: 10px;
    margin-top: 0!important;
    
}
.nav-sm .alerts-menu-badge{
        top: 4px;
        right: 4px;
        
}

.multiselect__placeholder{
        margin-bottom: 0px;
            padding-top: 6px;
}

.dummy-label {
    display: block;
}

.modal-xl{
    // width: 95vw!important;
    // height: 99vh!important;
    width: calc(100vw - 40px)!important;
    height: calc(100vh - 40px)!important;
    .modal-body{
        // max-height: 73vh!important;
        max-height: calc(100vh - 180px)!important;
        
    }
}

 .nav-sm .container.body .col-md-3.left_col {
     z-index: 99;
 }



 .contract-print-page{

.bordered {
        border: 3px solid $cTextDark;
    }

    .signature-placeholder {
        height: 100px;

    }

    .payment-info {
        padding-left: 0;

        li {
            list-style: none;

        }
    }

    /*.contract-wrap{
    display: flex;
    flex-direction: column;
    background-color: #ffddaa;*/
    .lower-box {
        flex: 1;
        display: flex;
        align-items: flex-end;
    }

    /*}*/

    #header-row {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        .table-customer-data{
        td {
            border: 1px solid $cTextDark;
        }
        }

        .header-row-col {
            flex: 1;
        }

        thead {
            h4 {
                margin: 0;
            }
        }
    }

    #footer-row {
        margin-bottom: 40px;
        display: flex;
        align-items: center;

        .footer-row-col {
            flex: 1;
        }

    }

    .tr-bold {
        font-weight: bold;
    }

    .contract-and-date {
        display: flex;
        flex-direction: row;

        &>div {
            flex: 1;
        }
    }

    .lopd {
        position: absolute;
        bottom: -280px;
        left: 110px;
        right: 110px;
        font-size: 10px;
        color: #999 !important;

        b {
            color: #999 !important;
        }


        a[href]:after {
            content: "";
        }
    }

 }

 .smart-label-dropdown{
    cursor: pointer;
    li{

        border-bottom: 1px solid $cShadow;
        &:last-child{
            border-bottom: 0;
        }
        a{
            padding: 6px 12px;
        }
    }
 }


 #signing-widget{
    display: flex;
    border: 1px solid $cShadow;
    background-color: $cBackgroundDark;
    padding: 4px 16px;
        margin-top: 8px;
            
        align-items: center;
        margin-right: 20px;
        .btn{
            margin: 0;
            margin-left: 12px;
        }
 }

.invoice-state-smart-label{
    .label-DRAFT{
        background-color: $cTextLight;
    }
    .label-ISSUED{
        background-color: $cStatePendingShipment;
    }
    .label-CHARGED{
        background-color: $cOk;
    }
    .label-CANCELLED{
        background-color: $cKo;
    }

}