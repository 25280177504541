.homepage-widgets-wrap{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .homepage-widget-wrap{
        /*flex: 1;*/
        padding: 10px;
        width: 33%;
        @media(max-width:768px){
            width: 50%;
        }
        @media(max-width:576px){
            width: 100%;
        }
        .homepage-contract-widget{
            position: relative;
            height: 100%;
            &.buy_sell{
                border-top: 4px solid #d9534f;
            }
            &.commission{
                border-top: 4px solid #f0ad4e;
            }
            &.sell{
                border-top: 4px solid $cOk;
            }
            &.deposit{
                border-top: 4px solid $cContractTypeDeposit;
            }
            
            &.state-active{
                
            }
            &.state-closed{
                background-color: $cOkBg
            }
            &.state-cancelled{
                background-color: $cKoBg
            }
            
            .currency-symbol{
                color: $cBackgroundDark;
                position: absolute;
                top: -5px;
                right: 10px;
                font-size: 50px;
                font-weight: bold;
                text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
            }
            .msc-badge{
                position: absolute;
                top: 4px;
                right: 4px;
            }
            .homepage-widget-chart-wrap{
                margin-top: 16px;
                margin-bottom: 16px;
            }
            .counter{
                margin-left: 8px;
                i{
                    color: $cShadowDark;
                }
            }
            .fa-caret-right{
                color: $cShadowDark;
                margin-left: 4px;
                margin-right: 4px;
            }
        }
    }
}